import { DASHBOARD_TYPE, DashboardRegion } from 'constants/index'
import { AccountRole, AccountType, DistributionStatus } from 'lib/types'
import { API } from 'services/aws-api'
import {
  EmailNotificationFormat,
  EmailNotificationSettings,
  EmailNotificationTypes
} from 'interfaces/EmailNotifications'
import { ViewType } from 'routes/DashboardPageV2/components/ActionBar/types'

interface dashboardCardsPreferences {
  LIVE: { isCollapsed: boolean }
  ANNOUNCED: { isCollapsed: boolean }
  PRICED: { isCollapsed: boolean }
  DRAFTS: { isCollapsed: boolean }
}
export interface DashboardFilterItems {
  subRegions: string[]
  countries: string[]
}
export interface DashboardFilters {
  [key: string]: DashboardFilterItems
}

export interface IEmailNotificationItem {
  emailNotificationTypes: EmailNotificationTypes[]
}

export interface IEmailNotificationPreferences {
  emailType: EmailNotificationFormat
  notificationSettings: EmailNotificationSettings
}

export interface UserPreferencesDTO {
  isNotificationCenterOpen: boolean
  isUsingDashboardForAll: boolean
  emailNotificationPreferences: IEmailNotificationPreferences
  dashboardCards: {
    IPO: dashboardCardsPreferences
    ABO: dashboardCardsPreferences
    OTH: dashboardCardsPreferences
    SPAC: dashboardCardsPreferences
    RI: dashboardCardsPreferences
    EQL: dashboardCardsPreferences
  }
  dashboardRegion: DashboardRegion
  viewType: ViewType
  dashboardFilters: DashboardFilters
}

interface UserConfigDTO {
  dashboardType: DASHBOARD_TYPE
  canAccessLockUpExpiry: boolean
  canSeeDashboardForAllSwitch: boolean
}

export interface ProfileDTO {
  user: {
    id: string
    email: string
    givenName: string
    familyName: string
    organizationId: string
    organizationName: string
    accountRole: AccountRole
    accountType: AccountType
    termsAndConditionsConsent: boolean
    distributionStatusOverride?: DistributionStatus
    countryAlpha3Code?: string
    preferences: UserPreferencesDTO
  }
  config: UserConfigDTO
  organization: {
    logo?: string
    commonName?: string
  }
}

export interface FeatureFlagDto {
  name: string
  description?: string
  enabled: boolean
}

export async function getUserProfile (): Promise<ProfileDTO> {
  return await API<ProfileDTO>().get({ path: '/v2/profile' })
}

export async function updateUserPreferences (preferences: UserPreferencesDTO): Promise<unknown> {
  return await API<unknown>().patch({
    path: '/v2/profile/user',
    init: {
      body: {
        preferences
      }
    }
  })
}

export async function updateUserEmailPreferences (
  emailNotificationPreferences: IEmailNotificationPreferences
): Promise<unknown> {
  return await API<unknown>().patch({
    path: '/v2/profile/user',
    init: {
      body: {
        preferences: { emailNotificationPreferences }
      }
    }
  })
}

export async function acceptTermsAndConditions (): Promise<unknown> {
  return await API<unknown>().patch({
    path: '/v2/profile/user',
    init: {
      body: {
        termsAndConditionsConsent: true
      }
    }
  })
}

export async function sendRejectTAndCEmail (user: Partial<ProfileDTO> | undefined): Promise<unknown> {
  return await API<unknown>().post({
    path: '/v2/profile/reject-t-and-c',
    init: {
      body: {
        user: user?.user,
        config: user?.config,
        organization: user?.organization
      }
    }
  })
}
