import { OFFERING_TYPE, TermsheetRegion } from '../constants'
import { DateTime } from 'luxon'
import { API } from 'services/aws-api'

export interface Notification {
  id: string
  link: string
  type: 'TERMSHEET_CREATED' | 'TERMSHEET_UPDATED' | 'TERMSHEET_CLEARED_FOR_SAU' | 'BOOKBUILDING_MESSAGE_CREATED'
  publishedDate: string
  publishingBankName: string
  issuerName: string
  message?: string
  offeringType: OFFERING_TYPE
  region: TermsheetRegion
  isCMAApprovalPostLaunch?: boolean
}

interface NotificationDto {
  type: 'TERMSHEET_CREATED' | 'TERMSHEET_UPDATED' | 'TERMSHEET_CLEARED_FOR_SAU' | 'BOOKBUILDING_MESSAGE_CREATED'
  organizationId: string
  publishedDate: string
  publishingBankName: string
  issuerName: string
  message?: string
  termsheetId: string
  dealId: string
  offeringType: OFFERING_TYPE
  region: TermsheetRegion
  isCMAApprovalPostLaunch?: boolean
}

export async function getNotifications (): Promise<Notification[]> {
  const notifications = await API<NotificationDto[]>()
    .get({
      path: '/v2/feed-notifications',
      init: {}
    })
  return notifications
    .reverse()
    .slice(0, 100)
    .map(dto => {
      const publishedDate = DateTime.fromISO(dto.publishedDate).toLocaleString({
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
        timeZoneName: 'short'
      })
      return {
        id: dto.publishedDate,
        link: `/termsheets/${dto.termsheetId}`,
        type: dto.type,
        publishedDate,
        publishingBankName: dto.publishingBankName,
        issuerName: dto.issuerName,
        message: dto.message,
        offeringType: dto?.offeringType,
        region: dto.region,
        isCMAApprovalPostLaunch: dto?.isCMAApprovalPostLaunch
      }
    })
}
