import { NotificationsSidebar } from 'components/Notifications'
import React, { ReactNode } from 'react'
import { TermsAndConditionsModal } from 'contexts/modal-context/TermsAndConditionsModal'
import { NoMobileVersionMessage } from 'components/NoMobileVersionMessage'
import { SideMenu } from 'components/SideMenu'
import { InternalNavbar } from 'components/InternalNavbar/InternalNavbar'
import { useIsSpecificPathWatch, useScreenMetadata } from 'lib/hooks'

interface Props {
  children: ReactNode
  isAuthenticated: boolean
}

export function AppContainer ({ children, isAuthenticated }: Props): JSX.Element {
  const { isMobile } = useScreenMetadata()
  const isAllowedPageForMobile = useIsSpecificPathWatch([
    {
      path: '/ecm/dashboard',
      compare: 'includes'
    }, {
      path: '/ecm/termsheet/',
      compare: 'includes'
    }
  ])
  if (isMobile && !isAllowedPageForMobile) {
    return (
      <div className='h-40 flex md:hidden flex-col'>
        <div className='h-full'>
          <NoMobileVersionMessage />
        </div>
      </div>
    )
  }
  return (
    <div className='flex flex-col mb-4'>
      {/* Here we had ECM nav bar which is being replaced with MUA's one */}
      {/* <NavbarDesktop isAuthenticated={isAuthenticated} /> */}
      {/* If, for some reason, you need its source code */}
      {/* you can find it in MR related to this ticket https://gitlab.com/primary-portal/ecm/-/issues/2104 */}
      <div className='md:flex md:grow overflow-hidden'>
        {isAuthenticated && (
          <SideMenu isAuthenticated />
        )}
        <main className='grow md:mx-10'>
          {isAuthenticated && (
            <InternalNavbar />
          )}
          {children ?? <span />}
        </main>
        <div className='shrink-0'>
          <NotificationsSidebar />
        </div>
      </div>
      <TermsAndConditionsModal />
    </div>
  )
}
