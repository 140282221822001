export const FEATURES = {
  notificationsForBanker: 'ecm-notifications-feed-for-banker-20220203',
  notificationsForInvestors: 'ecm-notifications-feed-for-investors-20220118',
  emailNotifications: 'taas-email_notifications-20211206',
  notificationsForSSS: 'ecm-notifications-feed-for-sss-20220325',
  termsheetAttachments: 'ecm-termsheet-attachments-20220601',
  manageIOIOrderLinkButtonInvestor: 'ecm-investor_manage_ioi_order_link_button-20220606',
  hotJarTracking: 'ecm-hotjar-tracking-20220704',
  newDealDropdown: 'ecm-new-deal-dropdown-2022-07-12',
  addPrefConnectionButton: 'ecm-enable_add_pref_connection_button-prod-20220808',
  showIOIOrderDealsIndicator: 'ecm-show_ioi_order_deals_indicator-20220922',
  showNavbarForMFE: 'ecm-show-navbar-for-mfe-2022-12-20',
  showIOIOrderIndicationsMenuItem: 'ecm-show_ioi_order_indications_menu_item-20230126',
  showSearchboxOnDashboardV2: 'ecm-show_serach_box_on_dashboardv2-20220213',
  showDealTypeFilterOnDashboardV2: 'ecm-enable-deal-type-filter-20230217',
  toggleGridListOnDashboardV2: 'ecm-toggle-list-grid-view-20230217',
  sendEmailsToNotSignedUpUsers: 'ecm-enable_send_emails_to_not_signed_up_users_20221503',
  showInternalNavbar: 'ecm-show-internal-navbar-20230320',
  enableTsv2ViewPage: 'ecm-enable-redirection-to-tsv2-view-page',
  redirectToDashboardV2: 'ecm-enable-redirection-to-dashboardv2-page',
  isAllDealsMyDealsSwitchVisible: 'ecm-is-all-deals-my-deals-switch-visible_20231108',
  showLeftHandNav: 'ecm-show-left-hand-nav-20230816'
}
