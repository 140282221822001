import { CancelledError, useMutation, useQuery, useQueryClient } from 'react-query'
import { AlertType } from 'ui/Alert/const'
import { AlertTime } from 'store/alertStore/const'
import pDebounce from 'p-debounce'
import { comparePaths, dbUpdateUserPreferences, getUserData } from 'lib/utils'
import { Options, ISpecificPath } from 'lib/types'
import { useToast } from 'lib/useToast'
import { UseMutationResult } from 'react-query/types/react/types'
import { getUserProfile, ProfileDTO, UserPreferencesDTO } from 'services/users'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { USER_PREFERENCES_KEY } from 'constants/queries'
import { always, cond, defaultTo, pathOr, pipe, propEq, T } from 'ramda'
import { useStores } from 'store/rootStore'
import { useHistory } from 'react-router-dom'
import { useWindowSize } from 'react-use'

const debouncedDbUpdate = pDebounce(dbUpdateUserPreferences, 5000)
const debouncedDbQuickUpdate = pDebounce(dbUpdateUserPreferences, 1500)
const getDebouncedUpdate = pipe(
  defaultTo({}),
  cond([
    [propEq<keyof Options>('immediate', true), always(dbUpdateUserPreferences)],
    [propEq<keyof Options>('quickUpdate', true), always(debouncedDbQuickUpdate)],
    [T, always(debouncedDbUpdate)]
  ]) as (arg: Options) => (newUserPreferences: UserPreferencesDTO) => Promise<void>
)
export const useMutationWithErrorHandling = (options?: Options): UseMutationResult<
void,
CancelledError,
UserPreferencesDTO,
void
> => {
  const { toast } = useToast()
  return useMutation(
    getDebouncedUpdate(options),
    {
      onError: (error) => {
        if (error instanceof CancelledError) return
        toast({
          alert: {
            type: AlertType.WARNING,
            message: {
              title: 'Network failure',
              description: 'We could not save your user preferences'
            }
          },
          ms: AlertTime.Short
        })
      }
    }
  )
}
export const useFilterStoreInit = (): void => {
  const queryClient = useQueryClient()
  const { filterStore } = useStores()
  const mutateUserPreferences = useMutationWithErrorHandling({ quickUpdate: true })
  const getUserDataFn = useCallback(async (): Promise<ProfileDTO> => await getUserData(queryClient),
    [queryClient]
  )
  const setUserDataFn = useCallback((data: ProfileDTO, preferences: UserPreferencesDTO) => {
    queryClient.setQueryData<ProfileDTO>(USER_PREFERENCES_KEY, data)
    void mutateUserPreferences.mutateAsync(preferences)
  }, [mutateUserPreferences, queryClient])

  useQuery<ProfileDTO>(['getUserData'], getUserProfile, {
    retryDelay: 5000,
    onSuccess: (userData) => {
      const filters = pathOr(
        {},
        ['user', 'preferences', 'dashboardFilters'],
        userData
      )
      if (!filterStore.isMounted) {
        filterStore.syncFilter(filters)
        filterStore.isMounted = true
      }
      filterStore.setUserData = setUserDataFn
      filterStore.getUserData = getUserDataFn
    }
  })
}
export const useIsSpecificPathWatch = (paths: ISpecificPath[]): boolean => {
  const [isMatch, setIsMatch] = useState(comparePaths(window.location.pathname, paths))
  const { listen } = useHistory()
  useEffect(() => {
    return listen((data: any) => {
      const pathName: string = data?.pathname ?? data.location.pathname ?? ''
      setIsMatch(
        comparePaths(pathName.startsWith('/ecm/') ? pathName : `/ecm${pathName}`, paths)
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return isMatch
}
export const useScreenMetadata = (): { isMobile: boolean, width: number } => {
  const { width } = useWindowSize()
  return useMemo(() => ({
    isMobile: width < 768,
    width
  }), [width])
}
